import { useEffect, useState } from 'react';
import classNames from 'classnames';

import { NavLink, useLocation } from 'react-router-dom';
import Lottie from 'react-lottie-player';
import CloseButton from '../assets/menu-close-button.json';
import menuLogo from '../assets/menu-logo.svg';
import menuFacebook from '../assets/menu-facebook.svg';
import menuInstagram from '../assets/menu-instagram.svg';
import menuLinkedIn from '../assets/menu-linkedin.svg';
import menuTikTok from '../assets/menu-tiktok.svg';

import './Menu.scss';

const closeButtonSegments: { [state: string]: [number, number] } = {
  init: [0, 19],
  mouseOver: [19, 0],
  mouseOut: [0, 19],
  mouseDown: [0, 19],
  mouseUp: [19, 0],
};

const Menu = ({ visible, hide }: { visible: boolean; hide: () => void }) => {
  const [segments, setSegments] = useState<[number, number]>(closeButtonSegments.init);

  const closeBtnOver = () => setSegments(closeButtonSegments.mouseOver);
  const closeBtnOut = () => setSegments(closeButtonSegments.mouseOut);
  const closeBtnDown = () => setSegments(closeButtonSegments.mouseDown);
  const closeBtnUp = () => setSegments(closeButtonSegments.mouseUp);
  const closeBtnClick = () => hide();

  const location = useLocation();
  const [sphere, setSphere] = useState<string>();
  useEffect(() => {
    setSphere(location.pathname.split('/')[1]);
  }, [location]);

  return (
    <>
      <div
        className={classNames({
          menu__overlay: true,
          menu__overlay_visible: visible,
        })}
        onClick={closeBtnClick}
      ></div>
      <aside
        className={classNames({
          menu: true,
          menu_visible: visible,
        })}
      >
        <Lottie
          loop={false}
          animationData={CloseButton}
          play={true}
          className="menu__close-button"
          direction={1}
          onMouseEnter={closeBtnOver}
          onMouseLeave={closeBtnOut}
          onMouseDown={closeBtnDown}
          onMouseUp={closeBtnUp}
          onClick={closeBtnClick}
          segments={segments}
        />
        <NavLink
          to="/showreels"
          className="menu__item"
          activeClassName="menu__item__active"
          onMouseEnter={() => setSphere('showreels')}
        >
          Showreels
        </NavLink>
        <NavLink
          to="/work"
          className="menu__item"
          activeClassName="menu__item__active"
          onMouseEnter={() => setSphere('work')}
        >
          Work
        </NavLink>
        <NavLink
          to="/about"
          className="menu__item"
          activeClassName="menu__item__active"
          onMouseEnter={() => setSphere('about')}
        >
          About
        </NavLink>
        <NavLink
          to="/services"
          className="menu__item"
          activeClassName="menu__item__active"
          onMouseEnter={() => setSphere('services')}
        >
          Services
        </NavLink>
        <a href="#get-in-touch" className="menu__item menu__item__special" onClick={closeBtnClick}>
          Get in touch
        </a>
        {sphere && (
          <div className="menu__sphere">
            <img src={`/spheres/${sphere}-main.png`} alt="" className="menu__sphere-image" />
          </div>
        )}
        <div className="menu__links">
          <NavLink to="/" className="menu__home" activeClassName="menu__home__active">
            <img src={menuLogo} className="menu__logo" alt="8points" />
          </NavLink>
          <div className="menu__social">
            <a
              href="https://www.facebook.com/8points-studio-100572609085688"
              target="_blank"
              className="menu__social-link"
              rel="noreferrer"
            >
              <img src={menuFacebook} className="menu__social-icon" alt="Facebook" />
            </a>
            <a
              href="https://www.instagram.com/8points_studio/"
              target="_blank"
              className="menu__social-link"
              rel="noreferrer"
            >
              <img src={menuInstagram} className="menu__social-icon" alt="Instagram" />
            </a>
            <a
              href="https://www.linkedin.com/company/8points"
              target="_blank"
              className="menu__social-link"
              rel="noreferrer"
            >
              <img src={menuLinkedIn} className="menu__social-icon" alt="LinkedIn" />
            </a>
            <a
              href="https://www.tiktok.com/@8points_studio?lang=en"
              target="_blank"
              className="menu__social-link"
              rel="noreferrer"
            >
              <img src={menuTikTok} className="menu__social-icon" alt="TikTok" />
            </a>
          </div>
        </div>
      </aside>
    </>
  );
};

export default Menu;
