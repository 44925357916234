import { useContext, useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { CommonContext } from '../App';

import { NavLink } from 'react-router-dom';
import GetInTouch from './GetInTouch';
import ScrollMotion from './ScrollMotion';
import Menu from './Menu';

import Lottie from 'react-lottie-player';
import CloseButton from '../assets/menu-button.json';
import LogoButton from '../assets/8point-button.json';

import './PageLayout.scss';

const menuButtonSegments: { [state: string]: [number, number] } = {
  init: [0, 1],
  hide: [180, 1],
  show: [1, 180],
  mouseOver: [180, 197],
  mouseOut: [197, 180],
  mouseDown: [197, 180],
  mouseUp: [180, 197],
};

const logoButtonSegments: { [state: string]: [number, number] } = {
  init: [0, 1],
  hide: [137, 0],
  show: [0, 137],
};

const PageLayout: React.FC = ({ children }) => {
  const { state } = useContext(CommonContext);

  const [menuSegments, setMenuSegments] = useState<[number, number]>(menuButtonSegments.init);
  const [logoSegments, setLogoSegments] = useState<[number, number]>(logoButtonSegments.init);

  const [menuVisible, setMenuVisible] = useState(false);

  const menuBtnOver = () => buttonsVisibility && setMenuSegments(menuButtonSegments.mouseOver);
  const menuBtnOut = () => buttonsVisibility && setMenuSegments(menuButtonSegments.mouseOut);
  const menuBtnDown = () => buttonsVisibility && setMenuSegments(menuButtonSegments.mouseDown);
  const menuBtnUp = () => buttonsVisibility && setMenuSegments(menuButtonSegments.mouseUp);
  const menuBtnClick = () => setMenuVisible(true);

  const logoBtnClick = () => window.scrollTo({ top: 0, behavior: 'smooth' });

  const [buttonsVisibility, setButtonsVisibility] = useState(false);

  const { ref: buttonsTriggerRef, inView } = useInView({ threshold: 1, triggerOnce: false });
  const isInView = useRef<Boolean>(false);

  useEffect(() => {
    isInView.current = inView;
    setTimeout(() => checkButtonsVisibility(), 100);
    // eslint-disable-next-line
  }, [inView]);

  const checkButtonsVisibility = () => {
    if (buttonsVisibility && isInView.current) {
      setMenuSegments(menuButtonSegments.hide);
      setLogoSegments(logoButtonSegments.hide);
    } else if (!buttonsVisibility && !isInView.current) {
      setMenuSegments(menuButtonSegments.show);
      setLogoSegments(logoButtonSegments.show);
    }
    setButtonsVisibility(!isInView.current);
  };

  return (
    <div className="page-layout">
      <div className="page-layout__left-bar">
        <Lottie
          loop={false}
          animationData={LogoButton}
          play={true}
          className="page-layout__logo-button"
          direction={1}
          onClick={logoBtnClick}
          segments={logoSegments}
        />
      </div>
      <ScrollMotion
        className="page-layout__header"
        active={!state.initialAnimation}
        threshold={0.1}
        triggerOnce={true}
        hiddenState={{
          translateY: -80,
        }}
        visibleState={{
          transition: {
            delay: 0.5,
            duration: 0.3,
            easing: 'ease-out',
          },
          translateY: 0,
        }}
      >
        <div className="page-layout__logo">
          <NavLink to="/">
            <img src="/logo.svg" width="140" alt="8points" />
          </NavLink>
        </div>
        <div className="page-layout__navigation">
          <NavLink to="/showreels" className="page-layout__nav-item" activeClassName="page-layout__nav-item__active">
            Showreels
          </NavLink>
          <NavLink to="/work" className="page-layout__nav-item" activeClassName="page-layout__nav-item__active">
            Work
          </NavLink>
          <NavLink to="/about" className="page-layout__nav-item" activeClassName="page-layout__nav-item__active">
            About
          </NavLink>
          <NavLink to="/services" className="page-layout__nav-item" activeClassName="page-layout__nav-item__active">
            Services
          </NavLink>
          <a href="#get-in-touch" className="page-layout__nav-item page-layout__nav-item__special">
            Get in touch
          </a>
        </div>
      </ScrollMotion>
      <div className="page-layout__buttons-trigger" ref={buttonsTriggerRef} />
      <main>{children}</main>
      <GetInTouch />
      <div className="page-layout__right-bar">
        <Lottie
          loop={false}
          animationData={CloseButton}
          play={true}
          className="page-layout__menu-button"
          direction={1}
          onMouseEnter={menuBtnOver}
          onMouseLeave={menuBtnOut}
          onMouseDown={menuBtnDown}
          onMouseUp={menuBtnUp}
          onClick={menuBtnClick}
          segments={menuSegments}
        />
        <div className="page-layout__email">
          <a href="mailto:hello@8points.lu">hello@8points.lu</a>
        </div>
        <div className="page-layout__menu-button" />
      </div>
      <div className="page-layout__lines">
        <div className="page-layout__line page-layout__line__first"></div>
        <div className="page-layout__line page-layout__line__moving"></div>
        <div className="page-layout__line page-layout__line__moving"></div>
        <div className="page-layout__line page-layout__line__moving"></div>
        <div className="page-layout__line page-layout__line__moving"></div>
        <div className="page-layout__line page-layout__line__moving"></div>
        <div className="page-layout__line page-layout__line__last"></div>
      </div>
      <Menu visible={menuVisible} hide={() => setMenuVisible(false)} />
    </div>
  );
};

export default PageLayout;
