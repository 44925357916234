import { Link } from 'react-router-dom';
import './ProjectTile.scss';
import ScrollMotion from './ScrollMotion';

type ProjectTileProps = {
  id: string;
  title: string;
  type?: string;
  image?: string;
  position: 'left' | 'right';
  key: string;
};

const visibleState = {
  opacity: 1,
  translateX: 0,
};

const ProjectTile = ({ id, title, type, image, position }: ProjectTileProps) => (
  <Link to={`/work/${id}`} className="project-tile">
    <ScrollMotion
      threshold={0.25}
      triggerOnce={true}
      hiddenState={{
        opacity: 0.3,
        translateX: -100 * (position === 'left' ? 1 : -1),
      }}
      visibleState={visibleState}
    >
      <div className="project-tile__image">
        <img src={image} alt={title} />
      </div>
      <div className="project-tile__content">
        <div className="project-tile__title">{title}</div>
        {type && <div className="project-tile__type">{type}</div>}
      </div>
    </ScrollMotion>
  </Link>
);

export default ProjectTile;
