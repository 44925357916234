import PageLayout from '../components/PageLayout';
import HomeTeaser from '../components/HomeTeaser';
import { ShowreelsContent } from './Showreels';
import { WorkContent } from './Work';

const HomePage = () => {
  return (
    <PageLayout>
      <HomeTeaser
        image="/jaguar-teaser-poster.jpg"
        title="It's time to frame the future"
        subtitle="We make commercial animation to level up your business"
      />
      <ShowreelsContent />
      <WorkContent />
    </PageLayout>
  );
};

export default HomePage;
