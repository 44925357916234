import { useState } from 'react';
import PageLayout from '../components/PageLayout';
import ProjectTile from '../components/ProjectTile';
import SectionTitle from '../components/SectionTitle';

import projects from '../data/projects.json';
import { TProject } from '../models/project';

const projectsPerPage = 4;

const WorkContent = () => {
  const [displayProjects, setDisplayProjects] = useState(projectsPerPage);

  const showNextPage = () => {
    setDisplayProjects(state => state + projectsPerPage);
  };

  return (
    <div className="page-layout__content">
      <SectionTitle sphere="/spheres/work-main.png">Work</SectionTitle>
      {projects
        .filter((_, idx: number) => idx < displayProjects)
        .map((project: TProject, idx: number) => (
          <ProjectTile
            key={project.id}
            id={project.id}
            title={project.title}
            type={project.type}
            image={project.image}
            position={idx % 2 === 0 ? 'left' : 'right'}
          />
        ))}
      {projects.length > displayProjects && (
        <p className="page-layout__center">
          <button className="button" onClick={showNextPage}>
            Show more
          </button>
        </p>
      )}
    </div>
  );
};

const WorkPage = () => (
  <PageLayout>
    <WorkContent />
  </PageLayout>
);

export default WorkPage;
export { WorkContent };
