import { useLayoutEffect, useRef } from 'react';
import CloseX from '../assets/close-x.svg';

import './PlayerOverlay.scss';

type PlayerOverlayProps = {
  hideOverlay: () => void;
  visible?: boolean;
  children: React.ReactNode;
};

const PlayerOverlay = ({ hideOverlay, visible = false, children }: PlayerOverlayProps) => {
  const scrollPos = useRef(0);

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (visible) {
        window.scrollTo(0, scrollPos.current);
      }
    };

    if (visible) {
      scrollPos.current = window.scrollY;
      window.addEventListener('scroll', handleScroll);
    } else {
      window.removeEventListener('scroll', handleScroll);
    }

    return () => window.removeEventListener('scroll', handleScroll);
    // eslint-disable-next-line
  }, [visible]);

  if (visible) {
    return (
      <div className="player-overlay__overlay" onClick={hideOverlay}>
        <div className="player-overlay__container">
          <img src={CloseX} alt="Close Video" className="player-overlay__close" />
          {children}
        </div>
      </div>
    );
  }
  return null;
};

export default PlayerOverlay;
