import Vimeo from '@u-wave/react-vimeo';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import PageLayout from '../components/PageLayout';
import projects from '../data/projects.json';
import { TProject } from '../models/project';

import './Project.scss';

const ProjectPage = () => {
  let { projectId }: { projectId: string } = useParams();
  const [project, setProject] = useState<TProject | undefined>(projects.find(p => p.id === projectId));

  useEffect(() => {
    if (project) {
      setProject(projects.find(p => p.id === projectId));
    }
    // eslint-disable-next-line
  }, [projectId]);

  const [prevProject, setPrevProject] = useState<TProject | undefined>();
  const [nextProject, setNextProject] = useState<TProject | undefined>();
  useEffect(() => {
    if (project) {
      const projectNo = projects.findIndex(p => p.id === project.id);
      setPrevProject(projects[projectNo > 0 ? projectNo - 1 : projects.length - 1]);
      setNextProject(projects[projectNo < projects.length - 1 ? projectNo + 1 : 0]);
    }
    // eslint-disable-next-line
  }, [project]);

  return (
    <PageLayout>
      <div className="project-page">
        {project && (
          <div className="project-page__content">
            <div className="project-page__heading">
              {project.title && <h1 className="project-page__title">{project.title}</h1>}
              {project.type && <h3 className="project-page__type">{project.type}</h3>}
            </div>
            {project.vimeoId && (
              <Vimeo
                className="project-page__video"
                video={project.vimeoId}
                width="100%"
                showTitle={false}
                autoplay={false}
              />
            )}
            {!project.vimeoId && project.image && (
              <img className="project-page__image" src={project.image} alt={project.title} />
            )}
            {project.description && (
              <div
                className="project-page__description"
                dangerouslySetInnerHTML={{ __html: project.description }}
              ></div>
            )}

            {nextProject && (
              <Link to={`/work/${nextProject.id}`} className="project-page__next-link">
                {nextProject.title}
              </Link>
            )}
            {prevProject && (
              <Link to={`/work/${prevProject.id}`} className="project-page__prev-link">
                {prevProject.title}
              </Link>
            )}
            <br style={{ clear: 'both' }} />
          </div>
        )}
      </div>
    </PageLayout>
  );
};

export default ProjectPage;
