import { useRef, useState } from 'react';
import emailjs from 'emailjs-com';

import SectionTitle from './SectionTitle';
import { Link } from 'react-router-dom';

import menuFacebook from '../assets/footer-facebook.svg';
import menuInstagram from '../assets/footer-instagram.svg';
import menuLinkedIn from '../assets/footer-linkedin.svg';
import menuTikTok from '../assets/footer-tiktok.svg';

import './GetInTouch.scss';

const GetInTouch = () => {
  const formRef = useRef<HTMLFormElement>(null);
  const [formError, setFormError] = useState<string | null>(null);
  const [formSuccess, setFormSuccess] = useState<boolean>(false);

  const buttonClick = () => {
    if (formRef.current) {
      const valid = formRef.current.checkValidity();
      setFormSuccess(false);
      if (valid) {
        setFormError(null);
      } else {
        setFormError('To submit the form, please, fill out all the mandatory fields.');
      }
    }
  };

  const clearMessages = (evt: any) => {
    evt?.currentTarget?.setCustomValidity('');
    setFormSuccess(false);
    setFormError(null);
  };

  const sendEmail = (evt: any) => {
    evt.preventDefault();
    const params = {
      name: evt.target.name?.value || 'No name',
      email: evt.target.email?.value || 'No email',
      website: evt.target.website?.value || 'No website',
      company_size: evt.target.company_size?.value || 'No phone number',
      budget: evt.target.budget?.value || 'No budget',
      message: evt.target.message?.value || 'No message',
    };
    emailjs
      .send('service_gmail', 'template_a0cgmyg', params, 'user_BRj4NsPmB2hMPDXOLfkli')
      .then((result: any) => {
        setFormSuccess(true);
        console.log('Email sent successfully', result.text);
      })
      .catch((error: any) => {
        setFormError('The email was not sent due to the technical reasons. Please try again later.');
        console.error('Email was not sent!', error);
      });
  };

  return (
    <div className="get-in-touch" id="get-in-touch">
      <SectionTitle headerOne={false}>Get in touch</SectionTitle>
      <div className="get-in-touch__content">
        <div className="get-in-touch__form">
          <p>Please, fill out this quick form to help us understand the video project:</p>
          <div className="get-in-touch__columns">
            <form onSubmit={sendEmail} ref={formRef}>
              <div className="get-in-touch__field">
                <label htmlFor="name">
                  What is your name?
                  <span title="This field is mandatory" aria-label="required">
                    *
                  </span>
                </label>
                <input
                  type="text"
                  id="name"
                  placeholder="Full name"
                  required
                  onInvalid={evt => evt.currentTarget.setCustomValidity('Please, enter your name')}
                  onInput={clearMessages}
                />
              </div>
              <div className="get-in-touch__field">
                <label htmlFor="company_size">
                  What is your phone number?
                  <span title="This field is mandatory" aria-label="required">
                    *
                  </span>
                </label>
                <input
                  type="text"
                  id="company_size"
                  placeholder="+352 123 456"
                  required
                  onInvalid={evt => evt.currentTarget.setCustomValidity('Please, enter your phone number')}
                  onInput={clearMessages}
                />
              </div>
              <div className="get-in-touch__field">
                <label htmlFor="email">What is your email?*</label>
                <input
                  type="text"
                  id="email"
                  placeholder="ceo@company.com"
                  required
                  onInvalid={evt => evt.currentTarget.setCustomValidity('Please, enter your email address')}
                  onInput={clearMessages}
                />
              </div>
              <div className="get-in-touch__field">
                <label htmlFor="website">What is your website address?</label>
                <input type="text" id="website" placeholder="www.company.com" onInput={clearMessages} />
              </div>
              <div className="get-in-touch__field">
                <label htmlFor="budget">
                  What is your budget?
                  <span title="This field is mandatory" aria-label="required">
                    *
                  </span>
                </label>
                <select id="budget" defaultValue="1 000 - 5 000€" onInput={clearMessages}>
                  <option>1 000 - 5 000€</option>
                  <option>5 000 — 15 000€</option>
                  <option>15 000 — 25 000€</option>
                  <option>25 000 — 50 000€</option>
                  <option>50 000€ +</option>
                  <option>Don't know at this stage</option>
                </select>
              </div>
              <div className="get-in-touch__field__wide">
                <label htmlFor="message">
                  How we can help you?
                  <span title="This field is mandatory" aria-label="required">
                    *
                  </span>
                </label>
                <textarea
                  id="message"
                  rows={4}
                  placeholder="Your message"
                  required
                  onInvalid={evt =>
                    evt.currentTarget.setCustomValidity('Please, describe what is your task in a few words')
                  }
                  onInput={clearMessages}
                />
              </div>
              <button className="get-in-touch__button" type="submit" onClick={buttonClick}>
                Send
              </button>

              {formError && <div className="get-in-touch__error">{formError}</div>}
              {formSuccess && (
                <div className="get-in-touch__success">
                  Your message was successfully delivered.
                  <br />
                  We will contact with you soon.
                </div>
              )}
            </form>
            <div className="get-in-touch__contacts">
              <div className="get-in-touch__contact">
                <label htmlFor="contact-email">Write us</label>
                <div className="get-in-touch__contact-info" id="contact-email">
                  <a href="mailto:hello@8points.lu">hello@8points.lu</a>
                </div>
              </div>
              <div className="get-in-touch__contact">
                <label htmlFor="contact-phone">Call us</label>
                <div className="get-in-touch__contact-info" id="contact-phone">
                  <a href="tel:+352661254913">+352 691 186 114</a>
                </div>
              </div>
              <div className="get-in-touch__contact">
                <label htmlFor="contact-address">We are here</label>
                <div className="get-in-touch__contact-info" id="contact-address">
                  <a
                    href="https://www.google.com/maps/dir/186+Rue+des+Sources,+2542+Luxembourg"
                    target="_blank"
                    rel="noreferrer"
                  >
                    186 Rue des Sources,
                    <br />
                    L-2542, Luxembourg
                  </a>
                </div>
              </div>
              <div className="get-in-touch__contact">
                <a
                  href="https://www.facebook.com/8points-studio-100572609085688"
                  target="_blank"
                  className="menu__social-link"
                  rel="noreferrer"
                >
                  <img src={menuFacebook} className="menu__social-icon" alt="Facebook" />
                </a>
                <a
                  href="https://www.instagram.com/8points_studio/"
                  target="_blank"
                  className="menu__social-link"
                  rel="noreferrer"
                >
                  <img src={menuInstagram} className="menu__social-icon" alt="Instagram" />
                </a>
                <a
                  href="https://www.linkedin.com/company/8points"
                  target="_blank"
                  className="menu__social-link"
                  rel="noreferrer"
                >
                  <img src={menuLinkedIn} className="menu__social-icon" alt="LinkedIn" />
                </a>
                <a
                  href="https://www.tiktok.com/@8points_studio?lang=en"
                  target="_blank"
                  className="menu__social-link"
                  rel="noreferrer"
                >
                  <img src={menuTikTok} className="menu__social-icon" alt="TikTok" />
                </a>
              </div>
            </div>
          </div>

          <div className="get-in-touch__copy">
            Copyright © 2022 8points. All rights reserved.
            <Link to="/privacy">Privacy policy</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetInTouch;
