import { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

type ScrollMotionProps = {
  children: React.ReactNode;
  threshold?: number;
  triggerOnce?: boolean;
  hiddenState?: any;
  visibleState?: any;
  className?: string;
  active?: boolean;
};

const ScrollMotion = ({
  className,
  active = true,
  threshold = 0.5,
  triggerOnce = true,
  hiddenState = {},
  visibleState = {},
  children,
}: ScrollMotionProps) => {
  const controls = useAnimation();
  const { ref, inView } = useInView({ threshold, triggerOnce });

  useEffect(() => {
    controls.start(inView ? 'visible' : 'hidden');
  }, [controls, inView]);

  if (active) {
    return (
      <motion.span
        ref={ref}
        className={className}
        variants={{
          hidden: hiddenState,
          visible: {
            transition: {
              duration: 0.3,
            },
            ...visibleState,
          },
        }}
        initial="hidden"
        animate={controls}
      >
        {children}
      </motion.span>
    );
  }
  return <div className={className}>{children}</div>;
};

export default ScrollMotion;
