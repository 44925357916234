import PageLayout from '../components/PageLayout';
import SectionTitle from '../components/SectionTitle';
import Showreel from '../components/Showreel';

import showreels from '../data/showreels.json';
import { TShowreel } from '../models/showreel';

const ShowreelsContent = () => (
  <div className="page-layout__content">
    <SectionTitle sphere="/spheres/showreels-main.png">Showreels</SectionTitle>
    {showreels.map((showreel: TShowreel, idx: number) => (
      <Showreel
        key={`showreel-${showreel.vimeoId}`}
        title={showreel.title}
        description={showreel.description}
        image={showreel.image}
        tags={showreel.tags}
        vimeoId={showreel.vimeoId}
        youTubeId={showreel.youTubeId}
        position={idx % 2 === 0 ? 'left' : 'right'}
      />
    ))}
  </div>
);

const ShowreelsPage = () => (
  <PageLayout>
    <ShowreelsContent />
  </PageLayout>
);

export default ShowreelsPage;
export { ShowreelsContent };
