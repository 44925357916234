import { useState } from 'react';
import classNames from 'classnames';

import Lottie from 'react-lottie-player';
import PlayButton from '../assets/showreel-play-button.json';
import VideoPlayer from './VideoPlayer';
import ScrollMotion from './ScrollMotion';

import './Showreel.scss';

type ShowreelProps = {
  title: string;
  tags?: string[];
  description?: string;
  image?: string;
  youTubeId?: string;
  vimeoId?: string;
  position: 'left' | 'right';
};

const playButtonSegments: { [state: string]: [number, number] } = {
  init: [0, 250],
  mouseOver: [250, 265],
  mouseOut: [265, 250],
  mouseDown: [265, 250],
  mouseUp: [250, 265],
};

const visibleState = {
  opacity: 1,
  translateX: 0,
};

const Showreel = ({ title, tags, description, image, youTubeId, vimeoId, position = 'left' }: ShowreelProps) => {
  const [segments, setSegments] = useState<[number, number]>(playButtonSegments.init);

  const [hover, setHover] = useState(false);

  const mouseOver = () => {
    setSegments(playButtonSegments.mouseOver);
    setHover(true);
  };
  const mouseOut = () => {
    setSegments(playButtonSegments.mouseOut);
    setHover(false);
  };
  const mouseDown = () => {
    setSegments(playButtonSegments.mouseDown);
  };
  const onMouseUp = () => {
    setSegments(playButtonSegments.mouseUp);
  };

  const [videoPlayerVisible, setVideoPlayerVisible] = useState(false);

  const onClick = (evt?: any) => {
    if (evt) {
      evt.nativeEvent?.stopImmediatePropagation();
      evt.preventDefault();
      evt.stopPropagation();
    }
    if (youTubeId || vimeoId) {
      setVideoPlayerVisible(true);
    }
    return null;
  };

  return (
    <section className={`showreel showreel__${position}`}>
      <ScrollMotion
        threshold={0.15}
        triggerOnce={true}
        hiddenState={{
          opacity: 0.3,
          translateX: -100 * (position === 'left' ? 1 : -1),
        }}
        visibleState={visibleState}
      >
        <div
          className={classNames({
            'showreel__image-container': true,
            [`showreel__image-container__${position}`]: true,
            'showreel__image-container__hover': hover,
          })}
          onMouseEnter={mouseOver}
          onMouseLeave={mouseOut}
          onMouseDown={mouseDown}
          onMouseUp={onMouseUp}
          onClick={onClick}
        >
          <img className="showreel__image" src={image || '/showreel-default.png'} alt={title} />
          <div className="showreel__image-border"></div>

          <div className="showreel__play-button">
            <Lottie
              loop={false}
              animationData={PlayButton}
              play={true}
              style={{ marginTop: '3px' }}
              segments={segments}
              direction={1}
            />
          </div>
        </div>
      </ScrollMotion>
      <ScrollMotion
        className="showreel__content"
        threshold={0.15}
        triggerOnce={true}
        hiddenState={{
          opacity: 0.3,
          translateX: 100 * (position === 'left' ? 1 : -1),
        }}
        visibleState={visibleState}
      >
        <h3 className="showreel__title">{title}</h3>
        {tags && tags.length > 0 && (
          <p className="showreel__tags">
            {tags.map((tag: string, idx: number) => (
              <>
                <span className="showreel__tag" key={`${title}${idx}`}>
                  {tag}
                </span>{' '}
              </>
            ))}
          </p>
        )}
        {description && <p className="showreel__description">{description}</p>}
        {(youTubeId || vimeoId) && (
          <>
            <p className="showreel__video-link">
              <a
                href={youTubeId ? `https://www.youtube.com/watch?${youTubeId}` : `https://vimeo.com/${vimeoId}`}
                onMouseEnter={mouseOver}
                onMouseLeave={mouseOut}
                onMouseDown={mouseDown}
                onMouseUp={onMouseUp}
                onClick={onClick}
              >
                Watch video
              </a>
            </p>
            <VideoPlayer
              youTubeId={youTubeId}
              vimeoId={vimeoId}
              visible={videoPlayerVisible}
              hideOverlay={() => setVideoPlayerVisible(false)}
            />
          </>
        )}
      </ScrollMotion>
    </section>
  );
};

export default Showreel;
