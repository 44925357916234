import { ReactNode, useContext } from 'react';
import { CommonContext } from '../App';

import ScrollMotion from './ScrollMotion';

import './SectionTitle.scss';
import { useLocation } from 'react-router-dom';

type SectionTitleProps = {
  sphere?: string;
  children?: ReactNode;
  headerOne?: boolean;
};

const SectionTitle = ({ sphere, children, headerOne = true }: SectionTitleProps) => {
  const { state } = useContext(CommonContext);
  const location = useLocation();
  const pathLocation = location.pathname;
  console.log('SectionTitle', pathLocation);
  return (
    <div className="section-title">
      {sphere && (
        <div className="section-title__sphere">
          <img src={sphere} className="section-title__sphere-image" alt="" />
        </div>
      )}
      <div className="section-title__bg">
        {headerOne === true && pathLocation !== '/' ? (
          <h1 className="section-title__text">
            <ScrollMotion
              className="section-title__inner"
              threshold={0.1}
              triggerOnce={true}
              hiddenState={{
                top: '7vw',
                opacity: 0.1,
              }}
              visibleState={{
                transition: {
                  delay: state.initialAnimation ? 0 : 0.5,
                  duration: 0.2,
                  easing: 'ease-in-out',
                },
                top: 'calc(min(2.5vw, 50px))',
                opacity: 1,
              }}
            >
              {children}
            </ScrollMotion>
          </h1>
        ) : (
          <h2 className="section-title__text">
            <ScrollMotion
              className="section-title__inner"
              threshold={0.1}
              triggerOnce={true}
              hiddenState={{
                top: '7vw',
                opacity: 0.1,
              }}
              visibleState={{
                transition: {
                  delay: state.initialAnimation ? 0 : 0.5,
                  duration: 0.2,
                  easing: 'ease-in-out',
                },
                top: 'calc(min(2.5vw, 50px))',
                opacity: 1,
              }}
            >
              {children}
            </ScrollMotion>
          </h2>
        )}
      </div>
    </div>
  );
};

export default SectionTitle;
