import PageLayout from '../components/PageLayout';
import SectionTitle from '../components/SectionTitle';

import './Services.scss';

const ServicesPage = () => {
  return (
    <PageLayout>
      <div className="page-layout__content services__content">
        <SectionTitle sphere="/spheres/services-main.png">Services</SectionTitle>

        <ul className="services-list">
          <li>Marketing Animation</li>
          <li>Explainer Animation</li>
          <li>Social Media Animation</li>
          <li>Learning &amp; Development video</li>
          <li>Video Composing</li>
          <li>Video Editing</li>
        </ul>

        <h2 className="services-subtitle">Work in style</h2>
        <ul className="services-list">
          <li>2D Animation</li>
          <li>3D Animation</li>
          <li>Kinetic Text</li>
          <li>Isometric Animation</li>
          <li>Whiteboard Sketch</li>
          <li>Mixed Media</li>
        </ul>
      </div>
    </PageLayout>
  );
};

export default ServicesPage;
