import PlayerOverlay from './PlayerOverlay';
import Vimeo from '@u-wave/react-vimeo';
import YouTube from 'react-youtube';

type VimeoPlayerProps = {
  youTubeId?: string;
  vimeoId?: string | number;
  visible?: boolean;
  hideOverlay: () => void;
};

const VimeoPlayer = ({ youTubeId, vimeoId, visible = false, hideOverlay }: VimeoPlayerProps) => {
  return (
    <PlayerOverlay visible={visible} hideOverlay={hideOverlay}>
      {vimeoId && (
        <Vimeo
          className="player-overlay__player"
          video={vimeoId}
          width="100%"
          height="100%"
          showTitle={false}
          autoplay={true}
          onEnd={hideOverlay}
        />
      )}
      {youTubeId && (
        <YouTube
          containerClassName="player-overlay__player"
          videoId={youTubeId}
          opts={{
            width: '100%',
            height: '100%',
            playerVars: {
              autoplay: 1,
            },
          }}
          onEnd={hideOverlay}
        />
      )}
    </PlayerOverlay>
  );
};

export default VimeoPlayer;
