import { createContext, useEffect, useReducer, useState } from 'react';
import { motion, useMotionValue, useSpring } from 'framer-motion';

import { BrowserRouter, Switch, Route } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';

// pages
import ShowreelsPage from './pages/Showreels';
import ProjectPage from './pages/Project';
import WorkPage from './pages/Work';
import AboutPage from './pages/About';
import ServicesPage from './pages/Services';
import HomePage from './pages/Home';

import './App.css';
import PrivacyPolicyPage from './pages/PrivacyPolicy';

const initialState = {
  initialAnimation: false,
};

export const CommonContext = createContext({
  state: initialState,
  dispatch: () => {},
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ANIMATION_PASS':
      return {
        ...state,
        initialAnimation: true,
      };
    default:
      return state;
  }
};

function App() {
  /**
   * Cursor stuff
   */
  const cursorX = useMotionValue(-250);
  const cursorY = useMotionValue(-250);

  const springConfig = { damping: 100, stiffness: 700 };
  const cursorXSpring = useSpring(cursorX, springConfig);
  const cursorYSpring = useSpring(cursorY, springConfig);

  const [touch, setTouch] = useState(false);
  const touchDetected = () => setTouch(true);

  useEffect(() => {
    const moveCursor = e => {
      if (!touch) {
        cursorX.set(window.scrollX + e.clientX - 18);
        cursorY.set(window.scrollY + e.clientY - 18);
      }
    };

    window.addEventListener('touchstart', touchDetected);
    window.addEventListener('mousemove', moveCursor);

    return () => {
      window.removeEventListener('mousemove', moveCursor);
    };
    // eslint-disable-next-line
  }, []);

  // state
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    setTimeout(() => {
      dispatch({ type: 'ANIMATION_PASS' });
    }, 1000);
    // eslint-disable-next-line
  }, []);

  return (
    <CommonContext.Provider value={{ state, dispatch }}>
      <BrowserRouter>
        <ScrollToTop />
        <Switch>
          <Route path="/showreels">
            <ShowreelsPage />
          </Route>
          <Route path="/work/:projectId">
            <ProjectPage />
          </Route>
          <Route exact path="/work">
            <WorkPage />
          </Route>
          <Route path="/about">
            <AboutPage />
          </Route>
          <Route path="/services">
            <ServicesPage />
          </Route>
          <Route path="/privacy">
            <PrivacyPolicyPage />
          </Route>
          <Route path="/">
            <HomePage />
          </Route>
        </Switch>
      </BrowserRouter>
      {!touch && (
        <motion.div
          className="cursor"
          style={{
            translateX: cursorXSpring,
            translateY: cursorYSpring,
          }}
        />
      )}
    </CommonContext.Provider>
  );
}

export default App;
