import PageLayout from '../components/PageLayout';
import SectionTitle from '../components/SectionTitle';

const PrivacyPolicyPage = () => {
  return (
    <PageLayout>
      <div className="page-layout__content">
        <SectionTitle sphere="/spheres/about-main.png">Privacy Policy</SectionTitle>
        <div style={{ maxWidth: '850px', margin: 'auto' }}>
          <p>
            Protecting your private information is our priority. This Privacy Policy applies to https://8points.lu/, and
            8points Animation Studio and governs data collection and usage. For the purposes of this Privacy Policy,
            unless otherwise noted, all references to 8points include https://8points.lu/, 8points and 8points Animation
            Studio. The 8points website is a sales and marketing site. By using the 8points website, you consent to the
            data practices described in this statement.
          </p>

          <h3>Collection of your Personal Information</h3>

          <p>
            In order to better provide you with products and services offered, 8points may collect personally
            identifiable information, such as your:
          </p>
          <ul>
            <li>First and Last Name</li>
            <li>E-mail Address</li>
            <li>Phone Number</li>
            <li>Company Website</li>
            <li>Project Information</li>
          </ul>

          <p>
            We do not collect any personal information about you unless you voluntarily provide it to us. However, you
            may be required to provide certain personal information to us when you elect to use certain products or
            services. These may include: (a) registering for an account; (b) entering a sweepstakes or contest sponsored
            by us or one of our partners; (c) signing up for special offers from selected third parties; (d) sending us
            an email message; (e) submitting your credit card or other payment information when ordering and purchasing
            products and services. To wit, we will use your information for, but not limited to, communicating with you
            in relation to services and/or products you have requested from us. We also may gather additional personal
            or non-personal information in the future.
          </p>

          <h3>Use of your Personal Information</h3>
          <p>
            8points collects and uses your personal information to operate and deliver the services you have requested.
          </p>
          <p>
            8points may also use your personally identifiable information to inform you of other products or services
            available from 8points and its affiliates.
          </p>

          <h3>Sharing Information with Third Parties</h3>
          <p>8points does not sell, rent or lease its customer lists to third parties.</p>
          <p>
            8points may share data with trusted partners to help perform statistical analysis, send you email or postal
            mail, provide customer support, or arrange for deliveries. All such third parties are prohibited from using
            your personal information except to provide these services to 8points, and they are required to maintain the
            confidentiality of your information.
          </p>
          <p>
            8points may disclose your personal information, without notice, if required to do so by law or in the good
            faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal
            process served on 8points or the site; (b) protect and defend the rights or property of 8points; and/or (c)
            act under exigent circumstances to protect the personal safety of users of 8points, or the public.
          </p>

          <h3>Tracking User Behavior</h3>
          <p>
            8points may keep track of the websites and pages our users visit within 8points, in order to determine what
            8points services are the most popular. This data is used to deliver customized content and advertising
            within 8points to customers whose behavior indicates that they are interested in a particular subject area.
          </p>

          <h3>Automatically Collected Information</h3>
          <p>
            Information about your computer hardware and software may be automatically collected by 8points. This
            information can include: your IP address, browser type, domain names, access times and referring website
            addresses. This information is used for the operation of the service, to maintain quality of the service,
            and to provide general statistics regarding use of the 8points website.
          </p>

          <h3>Use of Cookies</h3>
          <p>
            The 8points website may use “cookies” to help you personalize your online experience. A cookie is a text
            file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or
            deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server
            in the domain that issued the cookie to you.
          </p>
          <p>
            One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose of
            a cookie is to tell the Web server that you have returned to a specific page. For example, if you
            personalize 8points pages, or register with 8points site or services, a cookie helps 8points to recall your
            specific information on subsequent visits. This simplifies the process of recording your personal
            information, such as billing addresses, shipping addresses, and so on. When you return to the same 8points
            website, the information you previously provided can be retrieved, so you can easily use the 8points
            features that you customized.
          </p>
          <p>
            You have the ability to accept or decline cookies. Most Web browsers automatically accept cookies, but you
            can usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies,
            you may not be able to fully experience the interactive features of the 8points services or websites you
            visit.
          </p>

          <h3>Links</h3>
          <p>
            This website contains links to other sites. Please be aware that we are not responsible for the content or
            privacy practices of such other sites. We encourage our users to be aware when they leave our site and to
            read the privacy statements of any other site that collects personally identifiable information.
          </p>

          <h3>Security of your Personal Information</h3>
          <p>
            8points secures your personal information from unauthorized access, use, or disclosure. 8points uses the
            following methods for this purpose:
          </p>
          <ul>
            <li>SSL Protocol</li>
          </ul>
          <p>
            When personal information (such as a credit card number) is transmitted to other websites, it is protected
            through the use of encryption, such as the Secure Sockets Layer (SSL) protocol. We strive to take
            appropriate security measures to protect against unauthorized access to or alteration of your personal
            information. Unfortunately, no data transmission over the Internet or any wireless network can be guaranteed
            to be 100% secure. As a result, while we strive to protect your personal information, you acknowledge that:
            (a) there are security and privacy limitations inherent to the Internet which are beyond our control; and
            (b) security, integrity, and privacy of any and all information and data exchanged between you and us
            through this Site cannot be guaranteed.
          </p>

          <h3>Right to Deletion</h3>
          <p>Subject to certain exceptions set out below, on receipt of a verifiable request from you, we will:</p>
          <ul>
            <li>Delete your personal information from our records; and</li>
            <li>Direct any service providers to delete your personal information from their records. </li>
          </ul>
          <p>
            Please note that we may not be able to comply with requests to delete your personal information if it is
            necessary to:
          </p>
          <ul>
            <li>
              Complete the transaction for which the personal information was collected, fulfill the terms of a written
              warranty or product recall conducted in accordance with federal law, provide a good or service requested
              by you, or reasonably anticipated within the context of our ongoing business relationship with you, or
              otherwise perform a contract between you and us;
            </li>

            <li>
              Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity; or
              prosecute those responsible for that activity;
            </li>

            <li>Debug to identify and repair errors that impair existing intended functionality;</li>

            <li>
              Exercise free speech, ensure the right of another consumer to exercise his or her right of free speech, or
              exercise another right provided for by law;
            </li>

            <li>
              Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest
              that adheres to all other applicable ethics and privacy laws, when our deletion of the information is
              likely to render impossible or seriously impair the achievement of such research, provided we have
              obtained your informed consent;
            </li>

            <li>
              Enable solely internal uses that are reasonably aligned with your expectations based on your relationship
              with us;
            </li>

            <li>Comply with an existing legal obligation; or</li>

            <li>
              Otherwise use your personal information, internally, in a lawful manner that is compatible with the
              context in which you provided the information.
            </li>
          </ul>

          <h3>Children Under Thirteen</h3>
          <p>
            8points does not knowingly collect personally identifiable information from children under the age of
            thirteen. If you are under the age of thirteen, you must ask your parent or guardian for permission to use
            this website.
          </p>

          <h3>E-mail Communications</h3>
          <p>
            From time to time, 8points may contact you via email for the purpose of providing announcements, promotional
            offers, alerts, confirmations, surveys, and/or other general communication. In order to improve our
            Services, we may receive a notification when you open an email from 8points or click on a link therein.
          </p>

          <p>
            If you would like to stop receiving marketing or promotional communications via email from 8points, you may
            opt out of such communications by clicking on the UNSUBSCRIBE button.
          </p>

          <h3>External Data Storage Sites</h3>
          <p>We may store your data on servers provided by third party hosting vendors with whom we have contracted.</p>

          <h3>Changes to this Statement</h3>
          <p>
            8points reserves the right to change this Privacy Policy from time to time. We will notify you about
            significant changes in the way we treat personal information by sending a notice to the primary email
            address specified in your account, by placing a prominent notice on our website, and/or by updating any
            privacy information. Your continued use of the website and/or Services available after such modifications
            will constitute your: (a) acknowledgment of the modified Privacy Policy; and (b) agreement to abide and be
            bound by that Policy.
          </p>

          <h3>Contact Information</h3>
          <p>
            8points welcomes your questions or comments regarding this Statement of Privacy Policy. If you believe that
            8points has not adhered to this Statement, please contact 8points at:
          </p>

          <p>8points Animation Studio</p>
          <p>
            32-34 Boulevard Grande Duchesse Charlotte
            <br />
            Luxembourg
          </p>

          <p>Email Address: hello@8points.lu</p>

          <p>Effective as of October 19, 2021</p>
        </div>
      </div>
    </PageLayout>
  );
};

export default PrivacyPolicyPage;
